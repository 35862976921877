.menu-container {

    background: url('../assets/images/menu/backg.webp');
    background-size: cover;

    &__elements {
        height: 100vh;
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        margin: 0;
        color: rgb(0, 0, 0);
        z-index: 0;
        font-family: "Montserrat", sans-serif;
        font-variant-caps: all-small-caps;

    }

    @media (max-width: 1060px) {
        &__elements {
            flex-direction: column;

            a:nth-child(1) {
                order: 3;
            }

            a:nth-child(2) {
                order: 1;
            }

            a:nth-child(3) {
                order: 2;
            }
        }
    }
}