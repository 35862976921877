body,
html {
  background: url("../assets/images/contact/backg.webp");
  height: 100%;
}

.contact {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  color: rgb(2, 164, 209);
  font-family: "Montserrat", sans-serif;
  font-variant-caps: all-small-caps;
  animation: zoomIn;
  animation-duration: 1s;

  &__shadow {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    box-shadow: rgb(197, 0, 251) 0px 7px 29px 0px;

    &-form {
      height: 98vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 706px;
      background-color: rgb(255, 241, 255);
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;

      &-paragraph {
        margin-bottom: 60px;
      }

      &-box {
        width: 90%;
        margin: 15px;
      }

      &-message {
        height: 17%;
      }

      &-tos {
        width: 90%;
        display: flex;
        justify-content: start;

        &-checkbox {
          display: block;
          position: relative;
          padding-left: 35px;
          margin-bottom: 12px;
          cursor: pointer;
          font-size: 22px;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;

          /* Create a custom checkbox */
          &-checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 25px;
            width: 25px;
            border: 1px solid rgb(236, 182, 3);
          }

          &-text {
            box-sizing: border-box;
            display: flex;
            justify-content: left;
            align-items: center;
            margin-top: 2px;
            font-size: 15px;
          }
        }

        /* Hide the browser's default checkbox */
        &-checkbox input {
          position: absolute;
          opacity: 0;
          cursor: pointer;
          height: 0;
          width: 0;
        }

        /* On mouse-over, add a grey background color */
        &-checkbox:hover input~&-checkbox-checkmark {
          background-color: rgb(236, 182, 3);
        }

        /* When the checkbox is checked, add a blue background */
        &-checkbox input:checked~&-checkbox-checkmark {
          background-color: rgb(236, 182, 3);
        }

        /* Create the checkmark/indicator (hidden when not checked) */
        &-checkbox-checkmark:after {
          content: "";
          position: absolute;
          display: none;
        }

        /* Show the checkmark when checked */
        &-checkbox input:checked~&-checkbox-checkmark:after {
          display: block;
        }

        /* Style the checkmark/indicator */
        &-checkbox &-checkbox-checkmark:after {
          left: 9px;
          top: 5px;
          width: 5px;
          height: 10px;
          border: solid white;
          border-width: 0 3px 3px 0;
          -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
          transform: rotate(45deg);
        }

      }

      &-button-holder {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 15px;

        &-button {
          position: relative;
          font-size: 18px;
          border-radius: 40em;
          width: 8em;
          height: 3em;
          z-index: 1;
          color: rgb(255, 241, 255);
          cursor: pointer;
          overflow: hidden;
          border: none;
          border: 2px solid rgb(255, 241, 255);
          box-shadow: rgb(197, 0, 251) 0px 7px 29px 0px;
          font-family: "Montserrat", sans-serif;
          font-variant-caps: all-small-caps;
          letter-spacing: 3px;
          margin-right: 30px;

          &-text {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            line-height: 48px;
            border-radius: 40em;
            border: none;
            background: linear-gradient(rgba(255, 255, 255, 0.473),
                rgba(150, 150, 150, 0.25));
            z-index: 1;
            backdrop-filter: blur(10px);
            -webkit-backdrop-filter: blur(10px);
            font-family: "Montserrat", sans-serif;
          }

          &-blob {
            position: absolute;
            z-index: -1;
            border-radius: 5em;
            width: 5em;
            height: 3em;
            transition: transform 0.3s ease-in-out, background 0.3s ease-in-out;
          }

          &-blob:nth-child(2) {
            left: 0em;
            top: 0;
            background: #ff930f;
          }

          &-blob:nth-child(3) {
            left: 1.8em;
            top: 0;
            z-index: -1;
            background: #bf0fff;
          }

          &-blob:nth-child(4) {
            left: 4em;
            top: -1em;
            background: #ff1b6b;
          }

          &-blob:nth-child(5) {
            left: 4.3em;
            top: 1.6em;
            background: #0061ff;
          }
        }

        &-button:hover &-button-blob:nth-child(2) {
          background: #0061ff;
        }

        &-button:hover &-button-blob:nth-child(3) {
          background: #ff1b6b;
        }

        &-button:hover &-button-blob:nth-child(4) {
          background: #bf0fff;
        }

        &-button:hover &-button-blob:nth-child(5) {
          background: #ff930f;
        }

        &-button:hover &-button-blob {
          transform: scale(1.3);
        }

        &-button:active {
          border: 2px solid white;
        }
      }

      &-input,
      &-message {
        font-family: Helvetica, Arial, sans-serif;
        font-weight: 500;
        font-size: 18px;
        background-color: transparent;
        border: none;
        transition: all 0.3s;
        padding: 13px;
        margin-bottom: 15px;
        width: 90%;
        box-sizing: border-box;
        border-bottom: 1px solid rgb(137, 23, 244);

        &::placeholder {
          font-size: 14px;
          font-weight: lighter;
          font-variant: small-caps;
        }

      }


    }

    &-image {
      height: 98vh;
      width: 529.8px;
      display: flex;
      background-image: url("../assets/images/contact/rabbit2.webp");
      background-size: cover;
      background-repeat: no-repeat;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-top: 2px solid rgb(255, 241, 255);
      border-right: 2px solid rgb(255, 241, 255);
      border-bottom: 2px solid rgb(255, 241, 255);
    }

  }

  ::placeholder {
    color: rgb(236, 182, 3);
  }
}

@media (max-width: 800px) {

  .contact {
    &__shadow {
      flex-direction: column;
      flex-flow: column-reverse;

      &-form {
        border-radius: 0px;

      }

      &-image {
        width: 706px;
        height: 800px;
        border: 2px solid #fff1ff;
        border-radius: 0px;
      }
    }
  }

  @media (max-width: 700px) {

    .contact {

      &__shadow-form {
        width: 350px;

        &-paragraph {
          text-align: center;
        }

        &-button-holder {
          justify-content: space-around;
          padding-bottom: 25px;

          &-button {
            margin: 0;
          }
        }

      }

      &__shadow-image {
        width: 350px;
        height: 450px;
        object-fit: contain;

      }

    }
  }
}