.makesItFlipping {
  transform: rotateY(0.5turn);
  background-color: blue;
}

.game-card {
  position: relative;
  width: 334px;
  height: 500px;
  border-radius: 8px;
  margin: 10px;
  cursor: pointer;
  color: rgb(2, 164, 209);
  font-family: "Montserrat", sans-serif;
  font-variant-caps: all-small-caps;
  font-weight: 300;
  letter-spacing: 1px;
  background-color: white;
  transition: 1s ease-in-out;
  transform-style: preserve-3d;
  backface-visibility: hidden;


  &__face {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    transition: 1s ease-in-out;
    transform: rotateY(0deg);
    padding: 5%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;

    img {
      object-fit: cover;
      border: 2px solid rgb(255, 241, 255);
      box-shadow: rgb(197, 0, 251) 0px 7px 29px 0px;
      width: 329px;
      height: 495px;
      border-radius: 7px;
      position: absolute;
    }

    &--back {
      transform: rotateY(0.5turn);
    }

    &--front {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      user-select: none;
      text-align: center;
      box-shadow: rgb(197, 0, 251) 0px 7px 29px 0px;
    }

    &--front:hover {
      color: rgb(236, 182, 3);
    }
  }

  .next-button {
    position: absolute;
    left: 100px;
    bottom: 64px;
    font-size: 18px;
    border-radius: 40em;
    width: 8em;
    height: 3em;
    z-index: 1;
    color: rgb(255, 241, 255);
    cursor: pointer;
    overflow: hidden;
    border: none;
    border: 2px solid rgb(255, 241, 255);
    box-shadow: rgb(197, 0, 251) 0px 7px 29px 0px;
    font-family: "Montserrat", sans-serif;
    font-variant-caps: all-small-caps;
    letter-spacing: 3px;
    backface-visibility: hidden;
  }

  .button-text {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    line-height: 3em;
    border-radius: 40em;
    border: none;
    background: linear-gradient(rgba(255, 255, 255, 0.473),
        rgba(150, 150, 150, 0.25));
    z-index: 1;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    font-family: "Montserrat", sans-serif;
  }

  .button-blob {
    position: absolute;
    z-index: -1;
    border-radius: 5em;
    width: 5em;
    height: 3em;
    transition: transform 0.3s ease-in-out, background 0.3s ease-in-out;
  }

  .button-blob:nth-child(2) {
    left: 0em;
    top: 0;
    background: #ff930f;
  }

  .button-blob:nth-child(3) {
    left: 1.8em;
    top: 0;
    z-index: -1;
    background: #bf0fff;
  }

  .button-blob:nth-child(4) {
    left: 4em;
    top: -1em;
    background: #ff1b6b;
  }

  .button-blob:nth-child(5) {
    left: 4.3em;
    top: 1.6em;
    background: #0061ff;
  }

  .next-button:hover .button-blob:nth-child(2) {
    background: #0061ff;
  }

  .next-button:hover .button-blob:nth-child(3) {
    background: #ff1b6b;
  }

  .next-button:hover .button-blob:nth-child(4) {
    background: #bf0fff;
  }

  .next-button:hover .button-blob:nth-child(5) {
    background: #ff930f;
  }

  .next-button:hover .button-blob {
    transform: scale(1.3);
  }

  .next-button:active {
    border: 2px solid white;
  }

}