.other-game {
  background: url("../assets/images/menu/backg.webp");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  font-family: "Montserrat", sans-serif;
  font-variant-caps: all-small-caps;

  &__shadow {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    box-shadow: rgb(197, 0, 251) 0px 7px 29px 0px;
    animation: slideInLeft;
    animation-duration: 1s;

    &-description {
      height: 90vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 706px;
      background-color: rgb(255, 241, 255);
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      color: rgb(2, 164, 209);

      &-title {
        color: rgb(2, 164, 209);
        padding-bottom: 30px;
      }

      &-subtitle {
        color: rgb(236, 182, 3);
        padding-bottom: 30px;
        width: 90%;
        text-align: center;
        font-weight: normal;
      }

      &-text {
        text-align: justify;
        padding: 30px;
        border-bottom: 1px solid #bf0fff;
        border-top: 1px solid #bf0fff;
        width: 80%;
      }

      &-button {
        position: relative;
        font-size: 18px;
        border-radius: 40em;
        width: 8em;
        height: 3em;
        z-index: 1;
        color: rgb(255, 241, 255);
        cursor: pointer;
        overflow: hidden;
        font-family: "Montserrat", sans-serif;
        font-variant-caps: all-small-caps;
        letter-spacing: 3px;
        border: 2px solid rgb(255, 241, 255);
        box-shadow: rgb(197, 0, 251) 0px 7px 29px 0px;
        margin-top: 30px;

        &-text {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
          line-height: 48px;
          border-radius: 40em;
          border: none;
          background: linear-gradient(rgba(255, 255, 255, 0.473),
              rgba(150, 150, 150, 0.25));
          z-index: 1;
          backdrop-filter: blur(10px);
          -webkit-backdrop-filter: blur(10px);
          font-family: "Montserrat", sans-serif;
        }

        &-blob {
          position: absolute;
          z-index: -1;
          border-radius: 5em;
          width: 5em;
          height: 3em;
          transition: transform 0.3s ease-in-out, background 0.3s ease-in-out;
        }

        &-blob:nth-child(2) {
          left: 0em;
          top: 0;
          background: #ff930f;
        }

        &-blob:nth-child(3) {
          left: 1.8em;
          top: 0;
          z-index: -1;
          background: #bf0fff;
        }

        &-blob:nth-child(4) {
          left: 4em;
          top: -1em;
          background: #ff1b6b;
        }

        &-blob:nth-child(5) {
          left: 4.3em;
          top: 1.6em;
          background: #0061ff;
        }
      }

      &-button:hover &-button-blob:nth-child(2) {
        background: #0061ff;
      }

      &-button:hover &-button-blob:nth-child(3) {
        background: #ff1b6b;
      }

      &-button:hover &-button-blob:nth-child(4) {
        background: #bf0fff;
      }

      &-button:hover &-button-blob:nth-child(5) {
        background: #ff930f;
      }

      &-button:hover &-button-blob {
        transform: scale(1.3);
      }

      &-button:active {
        border: 2px solid white;
      }
    }

    &-first-section {
      height: 90vh;
      width: 529.8px;
      display: flex;
      background-size: cover;
      background-repeat: no-repeat;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border-top: 2px solid rgb(255, 241, 255);
      border-right: 2px solid rgb(255, 241, 255);
      border-bottom: 2px solid rgb(255, 241, 255);
    }
  }


  @media (max-width: 700px) {
    &__shadow-description {
      height: 650px;
      width: 370px;
      border-radius: 0px;

      &-title {
        padding-bottom: 0px;
      }

      &-button {
        margin-top: 15px;
      }

      &-text {
        font-size: 13px;
      }

    }

    &__shadow-first-section {
      display: none;
    }

  }
}