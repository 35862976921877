.game-container {
  background: url("../assets/images/menu/backg.webp");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100%;
  color: rgb(2, 164, 209);
  font-family: "Montserrat", sans-serif;
  font-variant-caps: all-small-caps;

  &__back-button {
    position: absolute;
    left: 20px;
    top: 20px;
    font-size: 18px;
    border-radius: 40em;
    width: 8em;
    height: 3em;
    z-index: 1;
    color: rgb(255, 241, 255);
    cursor: pointer;
    overflow: hidden;
    border: 2px solid rgb(255, 241, 255);
    box-shadow: rgb(197, 0, 251) 0px 7px 29px 0px;
    font-family: "Montserrat", sans-serif;
    font-variant-caps: all-small-caps;
    letter-spacing: 3px;

    &-text {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      line-height: 48px;
      border-radius: 40em;
      border: none;
      background: linear-gradient(rgba(255, 255, 255, 0.473),
          rgba(150, 150, 150, 0.25));
      z-index: 1;
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
      font-family: "Montserrat", sans-serif;
    }

    &-blob {
      position: absolute;
      z-index: -1;
      border-radius: 5em;
      width: 5em;
      height: 3em;
      transition: transform 0.3s ease-in-out, background 0.3s ease-in-out;
    }

    &-blob:nth-child(2) {
      left: 0em;
      top: 0;
      background: #ff930f;
    }

    &-blob:nth-child(3) {
      left: 1.8em;
      top: 0;
      z-index: -1;
      background: #bf0fff;
    }

    &-blob:nth-child(4) {
      left: 4em;
      top: -1em;
      background: #ff1b6b;
    }

    &-blob:nth-child(5) {
      left: 4.3em;
      top: 1.6em;
      background: #0061ff;
    }

    &:hover &-blob:nth-child(2) {
      background: #0061ff;
    }

    &:hover &-blob:nth-child(3) {
      background: #ff1b6b;
    }

    &:hover &-blob:nth-child(4) {
      background: #bf0fff;
    }

    &:hover &-blob:nth-child(5) {
      background: #ff930f;
    }

    &:hover &-blob {
      transform: scale(1.3);
    }

    &:active {
      border: 2px solid white;
    }
  }

  p {
    margin-bottom: 60px;
  }
}