.background {
  height: 100%;
  width: 100%;
  background: url("../assets/images/menu/backg.webp");
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Montserrat", sans-serif;
  font-variant-caps: all-small-caps;
  font-weight: 300;
  flex-direction: column;
  letter-spacing: 3px;

  button {
    position: absolute;
    left: 20px;
    top: 20px;
    font-size: 18px;
    border-radius: 40em;
    width: 8em;
    height: 3em;
    z-index: 2;
    color: rgb(255, 241, 255);
    cursor: pointer;
    overflow: hidden;
    border: 2px solid rgb(255, 241, 255);
    box-shadow: rgb(197, 0, 251) 0px 7px 29px 0px;
    font-family: "Montserrat", sans-serif;
    font-variant-caps: all-small-caps;
    letter-spacing: 3px;
  }

  button .text {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    line-height: 48px;
    border-radius: 40em;
    border: none;
    background: linear-gradient(rgba(255, 255, 255, 0.473),
        rgba(150, 150, 150, 0.25));
    z-index: 1;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    font-family: "Montserrat", sans-serif;
  }

  button .blob {
    position: absolute;
    z-index: -1;
    border-radius: 5em;
    width: 5em;
    height: 3em;
    transition: transform 0.3s ease-in-out, background 0.3s ease-in-out;
  }

  button .blob:nth-child(2) {
    left: 0em;
    top: 0;
    background: #ff930f;
  }

  button .blob:nth-child(3) {
    left: 1.8em;
    top: 0;
    z-index: -1;
    background: #bf0fff;
  }

  button .blob:nth-child(4) {
    left: 4em;
    top: -1em;
    background: #ff1b6b;
  }

  button .blob:nth-child(5) {
    left: 4.3em;
    top: 1.6em;
    background: #0061ff;
  }

  button:hover .blob:nth-child(2) {
    background: #0061ff;
  }

  button:hover .blob:nth-child(3) {
    background: #ff1b6b;
  }

  button:hover .blob:nth-child(4) {
    background: #bf0fff;
  }

  button:hover .blob:nth-child(5) {
    background: #ff930f;
  }

  button:hover .blob {
    transform: scale(1.3);
  }

  button:active {
    border: 2px solid white;
  }

  p {
    margin-bottom: 60px;
  }
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style-type: none;
}

:root {
  --primary: #6a59ff;
  --white: #ffffff;
  --bg: #f5f5f5;
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 1.3rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  background: #797979;
  transition: all 0.5s ease-in-out;
}

::-webkit-scrollbar-thumb:hover {
  background: #222224;
}

::-webkit-scrollbar-track {
  background: #f9f9f9;
}

body {
  font-size: 1.6rem;
  background: var(--bg);
}

.container {
  max-width: 124rem;
  padding: 4rem 1rem;
  margin: 0 auto;
}

.swiper_container {
  height: 70rem;
  padding: 2rem 0;
  position: relative;
  animation: zoomIn;
  animation-duration: 1s;
}

.swiper-slide {
  width: 37rem !important;
  height: 42rem;
  position: relative;
  display: flex !important;
  align-items: center;

  .caption {
    position: absolute;
    background-color: rgb(255, 241, 255);
    font-size: 25px;
    padding: 7px;
    border-radius: 4px;
    right: -34px;
    bottom: 30px;
    color: rgb(2, 164, 209);
  }

  a:hover .caption {
    width: 367px;
    padding-right: 0;
    padding-left: 0;
    display: flex;
    justify-content: center;
    color: rgb(236, 182, 3);
  }

  @media (max-width: 1235px) {
    .caption {
      font-size: 15px;
      right: 0px;
    }

    a:hover .caption {
      width: 280px;
    }

  }
}

.swiper-3d .swiper-slide-shadow-top,
.swiper-slide-shadow-bottom {
  background-image: none !important;
}

.container a {
  width: 334px;
  height: 500px;
  border-radius: 7px;
  position: absolute;
  cursor: pointer;
}


.swiper-slide img {
  width: 37rem;
  height: 560px;
  border-radius: 2rem;
  object-fit: cover;
  border: 2px solid rgb(255, 241, 255);
  box-shadow: rgb(197, 0, 251) 0px 7px 29px 0px;
}

.swiper-slide-shadow-left,
.swiper-slide-shadow-right {
  display: none;
}


@media (max-width: 990px) {
  .slider-controler .swiper-button-next {
    left: 70% !important;
    transform: translateX(-70%) !important;
  }
}

@media (max-width: 500px) {
  .swiper_container {
    height: 47rem;
  }

  .swiper-slide {
    width: 28rem !important;
    height: 36rem !important;
  }

  .swiper-slide img {
    width: 28rem !important;
    height: 36rem !important;
  }

  .container a {
    width: 280px;
    height: 375px;
  }

}

@media (max-width: 450px) {
  .slider-controler .swiper-button-next {
    left: 80% !important;
    transform: translateX(-80%) !important;
  }
}

@media (max-width: 990px) {
  .slider-controler .swiper-button-prev {
    left: 30% !important;
    transform: translateX(-30%) !important;
  }
}

@media (max-width: 450px) {
  .slider-controler .swiper-button-prev {
    left: 20% !important;
    transform: translateX(-20%) !important;
  }
}